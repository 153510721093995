import * as React from "react"
import { Controller, useForm, FormProvider, useFieldArray } from "react-hook-form"
import { Button, Col, Input, Label, Row } from "reactstrap"
import { Typeahead, Menu, MenuItem, MenuProps } from "react-bootstrap-typeahead"
import { Link } from "react-router-dom"
import SparkMD5 from "spark-md5"
import DeviceLocationFormFields from "./DeviceLocationFormFields"
import NodeEditor, { NodeEditorUtils } from "@common/input/editor/NodeEditor"
import ISODateField from "@common/form/fields/ISODateField"
import Logger from "@common/Logger"
import Icon from "@common/display/Icon"
import "../../../../../SuperAdmin/dark.scss"

import i18n from "src/i18n"
const { t } = i18n

interface Contact {
  phone: string;
  name: string;
}

interface ContactEntry {
  id: string;
  label: string;
}

interface ServiceRequestFormProps {
  formData: any;
  projects: any[];
  project: any;
  devices: any[];
  tasks: string[];
  events: { id: string; cause: string }[];
  tools: string[];
  checklist: string[];
  serviceRequest: { id?: string };
  organizationId: string;
  onSubmit: (data: { formData: any }) => void;
  submitRef?: React.RefObject<HTMLButtonElement>;
  actions?: {
    changeView: (view: string) => void;
  };
}

export default function ServiceRequestForm(props: ServiceRequestFormProps) {
  const newServiceRequestId = SparkMD5.hash(new Date().getTime().toString())
  const form = useForm({
    defaultValues: props.formData,
    /* mode: "onChange" */
  })
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    watch,
    setValue,
    register,
  } = form

  const serviceProject = watch("location.project", props.project ? props.project.code : "")
  const serviceSite = watch("location.site")

  const projectFromProjectCode = (projectCode: string) => {
    return props.projects.find((project) => project.code === projectCode)
  }

  const siteFromSiteCode = (siteCode: string) => {
    const project = projectFromProjectCode(serviceProject)
    return project ? project.sites[siteCode] : false
  }

  const site = siteFromSiteCode(serviceSite)
  const contacts = site?.contacts
    ? site.contacts.map((contact: Contact) => {
      return { id: contact.phone, label: contact.name + ": " + contact.phone }
    })
    : []
  const defaultTasks = props.tasks.map((task) => {
    return { id: task, label: task }
  })
  const defaultEvents = props.events.map((event) => {
    return { id: event.id, label: event.cause }
  })
  const defaultTools = props.tools.map((tool) => {
    return { id: tool, label: tool }
  })
  const defaultChecklist = props.checklist.map((check) => {
    return { id: check, label: check }
  })

  const {
    fields: events,
    append: appendEvent,
    remove: removeEvent,
  } = useFieldArray({
    control,
    name: "events",
  })

  const {
    fields: tasks,
    append: appendTask,
    remove: removeTask,
  } = useFieldArray({
    control,
    name: "tasks",
    rules: { required: { value: true, message: "Work to be completed is required" } }
  })

  const {
    fields: tools,
    append: appendTool,
    remove: removeTool,
  } = useFieldArray({
    control,
    name: "tools",
  })

  const {
    fields: information,
    append: appendInformation,
    remove: removeInformation,
  } = useFieldArray({
    control,
    name: "information",
  })

  const {
    fields: checklist,
    append: appendCheck,
    remove: removeCheck,
  } = useFieldArray({
    control,
    name: "checklist",
  })

  function renderAutoCompleteMenu(results: { label?: string; customOption?: boolean }[], menuProps: MenuProps) {
    if (results) {
      if (!(results.length === 1 && results[0].customOption)) {
        return (
          <Menu {...menuProps}>
            {results
              .filter((result) => !result.customOption)
              .map((result, index) => {
                return (
                  <MenuItem key={index} option={result} position={index}>
                    {result.label ? result.label : result}
                  </MenuItem>
                )
              })}
          </Menu>
        )
      } else {
        return <></>
      }
    }
  }

  const onSubmit = async (data: Record<string, any>) => {
    props.onSubmit({ formData: data })
    Logger.info(data)
  }

  const getOptionLabel = (options: { id: string; label: string }[], id: string) => {
    const selectedOption = options.find((option) => option.id === id)
    const label = selectedOption ? selectedOption.label : undefined
    return label
  }

  const getOptionId = (options: { id: string; label: string }[], label: string) => {
    const selectedOption = options.find((option) => option.label === label)
    const id = selectedOption ? selectedOption.id : undefined
    return id
  }

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="side-by-side" style={{ paddingRight: "10px" }}>
            <h4>Location</h4>
            <DeviceLocationFormFields
              control={control}
              watch={watch}
              project={props.project}
              projects={props.projects}
              devices={props.devices}
              level={"system"}
            />
            <Controller
              name="closed"
              control={control}
              render={({ field }) => <ISODateField {...field} label={"Completed Date"} />}
            />
            <h4 style={{ marginTop: "8px" }}>{"Site Visit Window"}</h4>
            <Controller
              name="siteVisitWindow.start"
              control={control}
              render={({ field }) => <ISODateField {...field} label={"Start"} />}
            />

            <Controller
              name="siteVisitWindow.end"
              control={control}
              render={({ field }) => <ISODateField {...field} label={"End"} />}
            />

            <Label style={{ marginTop: "5px" }} for={"onSiteContactNotes"}>On site Contact notes</Label>
            <Controller
              name="onSiteContactNotes"
              control={control}
              render={({ field }) => (
                <Input type={"textarea"} id={field.name} /* name={"On site contact notes"} */ {...field} />
              )}
            />

            <Controller
              name="contactNumber"
              control={control}
              render={({ field }) => {
                const selectedContact = contacts.find((contact: ContactEntry) => contact.id === field.value)
                const label = selectedContact ? selectedContact.label : field.value
                return (
                  <>
                    <Label style={{ marginTop: "5px" }} for={"contactNumber"}>Contact number</Label>
                    <Typeahead
                      inputProps={{ required: true }}
                      id={"contactNumber"}
                      placeholder={""}
                      labelKey={"label"}
                      onChange={(tools) => {
                        if (tools && tools.length) {
                          field.onChange(tools[0].id)
                        }
                      }}
                      onInputChange={(value) => {
                        let newValue = ""
                        if (value && value.length > 0) {
                          newValue = value
                        }
                        field.onChange(newValue)
                      }}
                      selected={label ? [label] : []}
                      allowNew={true}
                      options={contacts}
                      renderMenu={(results, menuProps) => renderAutoCompleteMenu(results, menuProps)}
                    />
                  </>
                )
              }}
            />
            <h4>Events</h4>
            {events.map((event, index) => {
              return (
                <div key={index} className={"field-array-entry"}>
                  <Controller
                    name={`events.${index}`}
                    control={control}
                    render={({ field }) => {
                      const label = getOptionLabel(defaultEvents, field.value) ?? field.value
                      return (
                        <>
                          <Typeahead
                            inputProps={{ required: true }}
                            id={"events"}
                            placeholder={""}
                            onChange={(tools) => {
                              if (tools && tools.length) {
                                const id = getOptionId(defaultEvents, tools[0]) ?? tools[0]
                                field.onChange(id)
                              }
                            }}
                            onInputChange={(value) => {
                              let newValue = ""
                              if (value && value.length > 0) {
                                newValue = value
                              }
                              field.onChange(newValue)
                            }}
                            selected={label ? [label] : []}
                            allowNew={true}
                            options={site ? defaultEvents.map((contact) => contact.label) : []}
                            renderMenu={(results, menuProps) => renderAutoCompleteMenu(results, menuProps)}
                          />
                          {field.value?.length > 0 ? (
                              <Link to={"/events?eventId=" + field.value}>
                                <Button onClick={() => props.actions?.changeView("events")} color="info">
                                  {t("labels.view")}
                                </Button>
                              </Link>
                            ) : null}
                        </>
                      )
                    }}
                  />
                  <Button outline color={"danger"} type="submit" onClick={() => removeEvent(index)}>
                    <Icon icon="remove_circle" /> Remove event
                  </Button>
                </div>
              )
            })}
            <Button color={"primary"} type="button" onClick={() => appendEvent("")}>
              <Icon icon="add_circle" /> Add event
            </Button>
          </span>
          <span className="side-by-side" style={{ paddingLeft: "10px", paddingRight: "5px" }}>
            <Label style={{ marginTop: "5px" }} for={"taskSummary"}>Task Summary*</Label>
            <Controller
              name="taskSummary"
              control={control}
              render={({ field }) => <Input required={true} type={"textarea"} id={"taskSummary"} label={"Task Summary"} {...field} />}
            />

            <Label style={{ marginTop: "5px" }} for={"context"}>Context of issue*</Label>
            {<p className="text-danger">{form.formState.errors?.context?.message}</p>}
            <Controller
              name="context"
              control={control}
              rules={{ required: { value: true, message: "Context of issue is required" } }}
              render={({ field }) => {
                return (
                  <NodeEditor

                    id={"context"}
                    editorIdPrefix={props.serviceRequest.id ?? newServiceRequestId}
                    s3ImageBucket={`${process.env.REACT_APP_DOCUMENTS_BUCKET}/${props.organizationId}/service-requests/images/`}
                    // @ts-ignore
                    value={NodeEditorUtils.jsonStringToValue(field.value, true)}
                    onChange={(value) => {
                      if (NodeEditorUtils.isValueEmpty(value)) {
                        field.onChange(undefined)
                      } else {
                        field.onChange(NodeEditorUtils.valueToJSONString(value))
                      }
                    }}
                    /* {...field} */
                    copyIndicator={true}
                  />
                )
              }}
            />

            <h4 style={{ marginTop: "8px" }}>Work to be completed*</h4>
            {<p className="text-danger">{form.formState.errors?.tasks?.root?.message}</p>}
            {tasks.map((tasks, index) => {
              return (
                <div key={index} className={"field-array-entry"}>
                  <Controller
                    name={`tasks.${index}`}
                    control={control}
                    render={({ field }) => {
                      const label = field.value
                      return (
                        <>
                          <Typeahead
                            inputProps={{ required: true }}
                            id={"tasks"}
                            placeholder={""}
                            onChange={(tools) => {
                              if (tools && tools.length) {
                                field.onChange(tools[0])
                              }
                            }}
                            onInputChange={(value) => {
                              let newValue = ""
                              if (value && value.length > 0) {
                                newValue = value
                              }
                              field.onChange(newValue)
                            }}
                            selected={label ? [label] : []}
                            allowNew={true}
                            options={site ? defaultTasks.map((contact) => contact.label) : []}
                            renderMenu={(results, menuProps) => renderAutoCompleteMenu(results, menuProps)}
                          />
                        </>
                      )
                    }}
                  />
                  {index > 0 ? <Button outline color={"danger"} type="submit" onClick={() => removeTask(index)}>
                    <Icon icon="remove_circle" /> Remove Task
                  </Button> : null}

                </div>
              )
            })}
            <Button color={"primary"} type="button" onClick={() => appendTask("")}>
              <Icon icon="add_circle" /> Add Task
            </Button>

            <h4>Tools</h4>
            {tools.map((tool, index) => {
              return (
                <div key={index} className={"field-array-entry"}>
                  <Controller
                    name={`tools.${index}`}
                    control={control}
                    render={({ field }) => {
                      const label = field.value
                      return (
                        <>
                          <Typeahead
                            inputProps={{ required: true }}
                            id={"tools"}
                            placeholder={""}
                            onChange={(tools) => {
                              if (tools && tools.length) {
                                field.onChange(tools[0])
                              }
                            }}
                            onInputChange={(value) => {
                              let newValue = ""
                              if (value && value.length > 0) {
                                newValue = value
                              }
                              field.onChange(newValue)
                            }}
                            selected={label ? [label] : []}
                            allowNew={true}
                            options={site ? defaultTools.map((contact) => contact.label) : []}
                            renderMenu={(results, menuProps) => renderAutoCompleteMenu(results, menuProps)}
                          />
                        </>
                      )
                    }}
                  />
                  <Button outline color={"danger"} type="submit" onClick={() => removeTool(index)}>
                    <Icon icon="remove_circle" /> Remove Tool
                  </Button>
                </div>
              )
            })}
            <Button color={"primary"} type="button" onClick={() => appendTool("")}>
              <Icon icon="add_circle" /> Add Tool
            </Button>

            <h4>Information Needed</h4>
            {information.map((tool, index) => {
              return (
                <div key={index} className={"field-array-entry"}>
                  <Controller
                    name={`information.${index}.text`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <Label for={"text"}>Text</Label>
                        <Input type={"text"} id={"text"} label={"Text"} {...field} />
                      </>
                    )}
                  />
                  <Controller
                    name={`information.${index}.link`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <Label for={"link"}>Link</Label>
                        <Input type={"text"} id={"link"} label={"Link"} {...field} />
                      </>
                    )}
                  />
                  <Button outline color={"danger"} type="submit" onClick={() => removeInformation(index)}>
                    <Icon icon="remove_circle" /> Remove Information
                  </Button>
                </div>
              )
            })}
            <Button color={"primary"} type="button" onClick={() => appendInformation("")}>
              <Icon icon="add_circle" /> Add Information
            </Button>

            <h4>Checklist</h4>
            {checklist.map((tool, index) => {
              return (
                <div key={index} className={"field-array-entry"}>
                  <Controller
                    name={`checklist.${index}`}
                    control={control}
                    render={({ field }) => {
                      const label = field.value
                      return (
                        <>
                          <Typeahead
                            inputProps={{ required: true }}
                            id={"checklist"}
                            placeholder={""}
                            onChange={(tools) => {
                              if (tools && tools.length) {
                                field.onChange(tools[0])
                              }
                            }}
                            onInputChange={(value) => {
                              let newValue = ""
                              if (value && value.length > 0) {
                                newValue = value
                              }
                              field.onChange(newValue)
                            }}
                            selected={label ? [label] : []}
                            allowNew={true}
                            options={site ? defaultChecklist.map((contact) => contact.label) : []}
                            renderMenu={(results, menuProps) => renderAutoCompleteMenu(results, menuProps)}
                          />
                        </>
                      )
                    }}
                  />
                  <Button outline color={"danger"} type="submit" onClick={() => removeCheck(index)}>
                    <Icon icon="remove_circle" /> Remove Check
                  </Button>
                </div>
              )
            })}
            <Button color={"primary"} type="button" onClick={() => appendCheck("")}>
              <Icon icon="add_circle" /> Add Check
            </Button>
          </span>
          <Row className="ecogy-form-buttons">
            <Col className="button-section" sm="12">
              <Button
                variation="primary"
                type="submit"
                innerRef={props.submitRef}
                loadingText="Updating organization..."
                isDisabled={!isDirty}
              >
                Update
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </>
  )
}
